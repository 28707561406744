const React = require('react')
const connect = require('../connect')
const formIdConsumer = require('./form-id-consumer')
const PropTypes = require('prop-types')
const types = require('../../types')

const styles = require('../../styles/forms.css')

class SelectInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    if (this.props.value) {
      return
    }

    const {name, formId} = this.props
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: this.getSelectedValue(),
    })
  }

  onChange(ev) {
    const {name, formId} = this.props
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: ev.target.value,
    })
    this.props.onChange && this.props.onChange(ev)
  }

  getSelectedValue() {
    const {value: defaultValue, formData = {}} = this.props
    const value = formData.value || defaultValue
    return value
  }

  render() {
    const {name, values, label, className, formId} = this.props
    const id = `${formId}_${name}`
    const selectedValue = this.getSelectedValue()
    return (
      <div className={styles.selectContainer}>
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
        <select
          id={id}
          name={name}
          className={`${styles.selectElement} ${className}`}
          onChange={e => this.onChange(e)}
          value={selectedValue}
        >
          {values.map(({value, label}) => {
            const id = `${name}_${value}`
            return (
              <option value={value} key={id}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}

SelectInput.propTypes = {
  formId: PropTypes.string,
  value: PropTypes.string,
  formData: types.formDatum,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}

module.exports = connect()(formIdConsumer(SelectInput))
